body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --color-tone-1: #1a1a1b;
    --color-tone-2: #787c7e;
    --color-tone-3: #878a8c;
    --color-tone-4: #d3d6da;
    --color-tone-5: #edeff1;
    --color-tone-6: #f6f7f8;
    --color-tone-7: #ffffff;
    --opacity-50: rgba(255, 255, 255, 0.5);

    --green: #6aaa64;
    --darkendGreen: #538d4e;
    --yellow: #c9b458;
    --darkendYellow: #b59f3b;
    --lightGray: #d8d8d8;
    --gray: #86888a;
    --darkGray: #939598;
    --white: #fff;
    --black: #212121;
    --orange: #f5793a;
    --blue: #85c0f9;
    --header-height: 50px;
    --keyboard-height: 200px;
    --game-max-width: 500px;

    --color-present: var(--yellow);
    --color-correct: var(--green);
    --color-absent: var(--color-tone-2);
    --tile-text-color: var(--color-tone-7);
    --key-text-color: var(--color-tone-1);
    --key-evaluated-text-color: var(--color-tone-7);
    --key-bg: var(--color-tone-4);
    --key-bg-present: var(--color-present);
    --key-bg-correct: var(--color-correct);
    --key-bg-absent: var(--color-absent);
    --modal-content-bg: var(--color-tone-7);
}

.correct-button {
    background-color: var(--key-bg-correct) !important;
    color: var(--tile-text-color);
}

.present-button {
    background-color: var(--key-bg-present) !important;
}

.absent-button {
    background-color: var(--key-bg-absent) !important;
}

.App {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: white;
  color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.title {
  font-size: calc(10px + 2vmin);
  text-transform: uppercase;
}

.board {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 5px;
    padding: 10px;
    /*width: 261px;
    height: 312px;*/
    margin: 0 auto;
}

.tile {
    line-height: 3rem;
    width: 3rem;
    vertical-align: middle;
    box-sizing: border-box;
    text-transform: uppercase;
    user-select: none;
    color: var(--tile-text-color);
    font-weight: bold;
}

#suggestions {
    min-height: 10vh;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 0.1rem;
    border: 1px solid rgba(120, 120, 120, .3);
    border-radius: 20px;
    margin: 20px auto;
    padding: 20px;
}

.suggest-button {
    line-height: 2rem;
    margin: 0.2em;
    border: 1px solid rgba(120, 120, 120, .3);
    border-radius: 10px;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--key-evaluated-text-color);
    background-color: var(--key-bg-correct);
    cursor: pointer;
}

.suggest-input-button {
    line-height: 2rem;
    margin: 0.2em;
    border: 1px solid rgba(120, 120, 120, .3);
    border-radius: 10px;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--black);
    background-color: var(--color-tone-5);
}
